/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { css, jsx } from '@compiled/react';

const SIZE = 96;

const genericErrorStyles = css({
	width: `${SIZE}px`,
	height: `${SIZE}px`,
	display: 'block',
});

export const GenericErrorSVGV2 = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			viewBox="0 0 96 96"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			css={genericErrorStyles}
			{...props}
		>
			<path
				fill="#fff"
				d="M64.861 32.06a27.753 27.753 0 0 0-2.667-4.133 28.187 28.187 0 0 0-3.376-3.669c-6.985-6.311-15.57-6.89-23.609-2.53a30.226 30.226 0 0 0-4.056 2.656 30.624 30.624 0 0 0-3.735 3.4c-6.225 6.717-7.82 15.154-3.505 23.462a28.108 28.108 0 0 0 2.698 4.193 27.67 27.67 0 0 0 3.33 3.62c6.94 6.266 15.518 6.85 23.596 2.507a30.492 30.492 0 0 0 4.23-2.758 30.07 30.07 0 0 0 3.593-3.257c6.252-6.692 7.847-15.161 3.494-23.49h.007Z"
			/>
			<path
				fill="#DDDEE1"
				d="M43.092 66.774c-5.306 0-10.382-1.997-14.75-5.938a30.405 30.405 0 0 1-3.62-3.93 30.243 30.243 0 0 1-2.928-4.553c-4.657-8.961-3.247-18.509 3.871-26.192a33.271 33.271 0 0 1 4.025-3.67 32.834 32.834 0 0 1 4.378-2.869c9.209-4.995 18.816-3.951 26.353 2.86a30.945 30.945 0 0 1 3.665 3.98 30.303 30.303 0 0 1 2.898 4.488c4.705 9.003 3.295 18.565-3.868 26.231a32.148 32.148 0 0 1-3.879 3.515 33.415 33.415 0 0 1-4.562 2.975c-3.858 2.073-7.782 3.1-11.583 3.1v.003Zm2.53-45.486c-3.019 0-6.147.84-9.275 2.54a28.433 28.433 0 0 0-3.735 2.448 28.059 28.059 0 0 0-3.442 3.135c-5.746 6.203-6.86 13.566-3.135 20.736a25.395 25.395 0 0 0 2.472 3.83 25.304 25.304 0 0 0 3.044 3.309c5.99 5.41 13.398 6.182 20.862 2.175a28.02 28.02 0 0 0 3.899-2.545 28.03 28.03 0 0 0 3.31-2.999c5.777-6.182 6.883-13.552 3.124-20.75a25.553 25.553 0 0 0-2.44-3.777 25.848 25.848 0 0 0-3.087-3.358c-3.49-3.153-7.442-4.744-11.59-4.744h-.006Z"
			/>
			<path
				fill="#DDDEE1"
				d="M62.822 59.694c-1.235-1.72-2.562-1.71-3.857-.708-1.215.939-1.54 2.21-.244 3.882l1.48 1.913 4.102-3.174-1.48-1.913Z"
			/>
			<path fill="#DDDEE1" d="m65.334 60.811-6.159 4.762L70.748 80.54l6.16-4.761L65.333 60.81Z" />
			<path fill="#B7B9BE" d="m64.344 61.577-4.178 3.23 3.925 5.076 4.178-3.23-3.925-5.076Z" />
			<path
				fill="#DDDEE1"
				fillRule="evenodd"
				d="M55.057 33.452 36.213 52.296l-2.468-2.469 18.844-18.844 2.468 2.469Z"
				clipRule="evenodd"
			/>
			<path
				fill="#DDDEE1"
				fillRule="evenodd"
				d="M52.589 52.296 33.745 33.452l2.468-2.469 18.844 18.844-2.468 2.469Z"
				clipRule="evenodd"
			/>
		</svg>
	);
};
