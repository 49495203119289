import gql from 'graphql-tag';
import { type JsonLd } from 'json-ld-types';

import { type CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';

import { getApolloCache } from '@confluence/graphql';

// This is the JsonLd type we expect from the onResolve function, originating from linking platform's content resolver service.
// This schema is unstable & subject to change, so this resolver validates it before consuming it further
export type ResolveResponseType = {
	data: JsonLd.Data.Document;
	meta: JsonLd.Meta.BaseMeta;
};

const getContentIdFromResolvedData = (resolvedData: ResolveResponseType): string | undefined => {
	const ari = resolvedData.data['atlassian:ari'];
	return ari?.substring?.(ari?.lastIndexOf?.('/') + 1);
};

// data.data['@type'] will have two elements, for example ['schema:DigitalDocument', 'Document'], where 'schema:DigitalDocument' specifically denotes a live doc
// The resolve logic doesn't consistently position these two elements in the @type array, so the schema we need to validate may be in the 0th or 1st element
const isResolvedDataUnarchivedLiveDoc = (resolvedData: ResolveResponseType): boolean => {
	const typeArr = resolvedData.data['@type'];
	const isLiveDoc =
		typeArr?.[0] === 'schema:DigitalDocument' || typeArr?.[1] === 'schema:DigitalDocument';
	const isArchived = resolvedData.data['atlassian:state'] === 'archived';
	return isLiveDoc && !isArchived;
};

const isResolvedDataValid = (resolvedData: unknown): resolvedData is ResolveResponseType => {
	if (!resolvedData || typeof resolvedData !== 'object') return false;

	const data = (resolvedData as any).data;
	const meta = (resolvedData as any).meta;
	if (!data || !meta) return false;

	if (!('@context' in data)) return false;

	const type = data['@type'];
	if (typeof type !== 'string' && !Array.isArray(type)) return false;

	return true;
};

const SubtypeFragment = gql`
	fragment SubTypeUpdate on Content {
		id
		subType
		status
	}
`;

let hasInvalidResolverDataAnalyticsEventTriggered = false;
export const liveDocSmartCardResolver = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	resolvedData?: unknown,
) => {
	if (isResolvedDataValid(resolvedData)) {
		if (isResolvedDataUnarchivedLiveDoc(resolvedData)) {
			const contentId = getContentIdFromResolvedData(resolvedData);
			if (contentId) {
				const cache = getApolloCache();
				let existingCachedData: { subType: string; status: string } | null | undefined;
				try {
					existingCachedData = cache.readFragment({
						id: `Content:${contentId}`,
						fragment: SubtypeFragment,
					});
				} catch {
					// readFragment throws an error if no data is found
				}

				// Reads are cheaper than writes, so only write if we don't already have the correct data in cache.
				if (
					!existingCachedData ||
					existingCachedData?.subType !== 'live' ||
					existingCachedData?.status !== 'current'
				) {
					cache.writeFragment({
						id: `Content:${contentId}`,
						fragment: SubtypeFragment,
						// status is current if we are an unarchived live doc
						data: { __typename: 'Content', id: contentId, subType: 'live', status: 'current' },
					});
				}
			}
		}
	} else {
		// If resolved data schema is invalid, we likely need to update it ASAP as Linking Platform's content resolver service API may have changed
		if (!hasInvalidResolverDataAnalyticsEventTriggered) {
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					action: 'invalidResolverData',
					actionSubject: 'liveDocSmartCardResolver',
					source: 'confluenceSmartCardProvider',
				},
			}).fire();

			// Only send this event once per load to reduce load on analytics pipeline
			hasInvalidResolverDataAnalyticsEventTriggered = true;
		}
	}
};
