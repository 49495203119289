/* eslint-disable @atlaskit/editor/no-re-export */
// Entry file in package.json

import type { Scope } from '@atlassian/search-client';

import SearchResource from './api/SearchResource';

export const createSearchProvider = (
	cloudId: string,
	scope: Scope,
	aggregatorUrl?: string,
	analyticsIdentifier?: string,
	useConfluenceTypeInAri?: boolean,
) => {
	return new SearchResource(
		cloudId,
		scope,
		aggregatorUrl,
		analyticsIdentifier,
		useConfluenceTypeInAri,
	);
};

export { SearchResource };
