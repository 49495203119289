import React, { useMemo } from 'react';

import { SmartCardProvider } from '@atlaskit/link-provider';
import { useAnalyticsEvents, type CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';

import { fg } from '@confluence/feature-gating';
import { getLogger } from '@confluence/logger';

import { ConfluenceSmartCardClient } from './ConfluenceSmartCardClient';
import { liveDocSmartCardResolver } from './resolvers/liveDocSmartCardResolver';

// Only log first error to console, to prevent spamming console with resolver errors
let hasLoggedResolverError = false;
const defaultConfluenceResolveHandler = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	data?: unknown,
) => {
	try {
		// NOTE FOR DEVS OF RESOLVERS: The `data` object comes from linking platform's content resolver service which is an UNSTABLE API SCHEMA.
		// Ensure your resolver logic handles invalid schemas gracefully and is able to notify your team if the schema changes and your code needs to be updated.
		liveDocSmartCardResolver(createAnalyticsEvent, data);
	} catch (resolverError) {
		// prevent errors from causing smart card provider issues
		if (!hasLoggedResolverError) {
			const logger = getLogger('ConfluenceSmartCardProvider');
			logger.error`resolver error: ${resolverError}`;
			hasLoggedResolverError = true;
		}
	}
};

export const ConfluenceSmartCardProvider: typeof SmartCardProvider = (props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const smartCardClient = useMemo(
		() =>
			props?.client ||
			(fg('confluence_frontend_smart_card_client')
				? new ConfluenceSmartCardClient({
						onResolve: (data) => defaultConfluenceResolveHandler(createAnalyticsEvent, data),
					})
				: undefined),
		[props?.client, createAnalyticsEvent],
	);
	return <SmartCardProvider product="CONFLUENCE" {...props} client={smartCardClient} />;
};
