export class ShortcutsHistory {
	private size = 1;
	private stack: Array<{
		flagId?: string;
		lastDeletedItem?: any;
		undo(): void;
	}> = [];

	push({ undo, lastDeletedItem }: { undo(): void; lastDeletedItem: any }) {
		this.stack.push({ undo, lastDeletedItem });
		this.stack = this.stack.slice(-1 * this.size);
	}

	pop = () => this.stack.pop();

	peek = () => this.stack.slice(-1)[0];

	reset = () => {
		this.stack = [];
	};
}
