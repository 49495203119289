import type { FC } from 'react';
import React, { useMemo, useCallback, useContext, useState } from 'react';
import { styled } from '@compiled/react';
import { useIntl, defineMessages } from 'react-intl-next';

import {
	Card,
	TitleBlock,
	ActionName,
	SmartLinkPosition,
	SmartLinkDirection,
	SmartLinkSize,
} from '@atlaskit/smart-card';
import { CardSSR } from '@atlaskit/smart-card/ssr';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';
import Popup from '@atlaskit/popup';
import Blanket from '@atlaskit/blanket';
import type { LinkPickerPlugin } from '@atlaskit/link-picker';
import { LazyLinkPicker } from '@atlaskit/link-picker/lazy';

import { AtlassianLinkPickerPlugin } from '@atlassian/link-picker-atlassian-plugin';

import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import type { WithFlagsProps } from '@confluence/flags';
import { withFlags } from '@confluence/flags';
import { useSearchProvider } from '@confluence/editor-features';
import { START_TOUCH } from '@confluence/navdex';

import type { SpaceShortcutsSpaceNavigationQuery_spaceSidebarLinks_quick as SpaceNavigationQueryShortcutsType } from './__types__/SpaceShortcutsSpaceNavigationQuery';
import { useEditShortcut } from './useShortcuts';

export const isPageSSRd = () => {
	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	return process.env.REACT_SSR || window.__SSR_RENDERED__;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PopupTrigger = styled.span({
	float: 'right',
	maxWidth: 0,
	maxHeight: 0,
	visibility: 'hidden',
	overflow: 'hidden',
});

const i18n = defineMessages({
	shortcutsQuickActionsRemoveButton: {
		id: 'space-shortcuts.shortcuts.quick-actions.remove',
		defaultMessage: 'Remove',
		description: 'Text for remove button',
	},
});

type ShortcutsItemProps = {
	item: SpaceNavigationQueryShortcutsType;
	spaceId: string | null;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	isSpaceAdmin: boolean;
	links: SpaceNavigationQueryShortcutsType[];
	deleteShortcut: (item: SpaceNavigationQueryShortcutsType) => void;
};

//not exported from smartCard for some reason
type AnchorTarget = '_blank' | '_self' | '_top' | '_parent';

// string begins with 1+ characters other than "/", has a "/", and is followed by one character other than "/"
// OR string begins with "/", is followed by 1 character other than "/", ends with any # of any character
// while ignoring capitalization
const relativeUrlMatch = new RegExp(`^[^\/]+\/[^\/].*$|^\/[^\/].*$`, 'i');

// adding domain to relative confluence URLs so smart links don't error
export const getFullUrl = (url, isExternal) => {
	if (url === null) {
		return url;
	}
	if (!isExternal && relativeUrlMatch.test(url)) {
		return window.location.origin + url;
	}
	// edge case that doesn't match regexp
	if (url.match(`${window.location.protocol}///wiki`)) {
		return window.location.origin + url.replace(`${window.location.protocol}//`, '');
	}
	return url;
};

export const ShortcutsItemComponent: FC<ShortcutsItemProps & WithFlagsProps> = ({
	flags,
	item,
	spaceId,
	createAnalyticsEvent,
	isSpaceAdmin,
	links,
	deleteShortcut,
}) => {
	const intl = useIntl();
	const CardComponent = isPageSSRd() ? CardSSR : Card;
	let { url, styleClass } = item;
	url = url || ('' as string);
	const { title = '', id } = item;
	//styleClass is the old way of knowing if a link was internal or external
	styleClass = styleClass || '';

	const { matchRoute } = useContext(RoutesContext);
	let target: AnchorTarget = '_blank',
		navdexPointType: 'navigationSessionStartTouch' | null = null;
	try {
		const isRelativeUrl = matchRoute(url);
		const includesHostName = url.includes(window.location.hostname);
		const containsExternalStyleClass = styleClass.indexOf('external_link') >= 0;
		//now we also check if the link is relative or contains the pathname, falls back to styleclass
		const isExternal = isRelativeUrl
			? false
			: includesHostName
				? false
				: containsExternalStyleClass;
		url = getFullUrl(url, isExternal) || '';
		target = isExternal ? '_blank' : '_self';
		navdexPointType = !isExternal ? START_TOUCH : null;
	} catch {
		// eslint-disable-next-line no-console
		console.warn('malformed URL:', url);
		//prefer to show a different UI when the link is bad, letting the user know they should update the shortcut...
		//however the smartcard format doesn't really have a way to do this.
	}

	const [isEditOpen, setIsEditOpen] = useState(false);
	const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);

	const handleClose = useCallback(() => {
		setIsEditOpen(false);
	}, [setIsEditOpen]);

	const openEdit = useCallback(() => {
		setIsEditOpen(!isEditOpen);
	}, [isEditOpen, setIsEditOpen]);

	const handleSubmit = useEditShortcut(links, flags, spaceId);

	const onSubmit = useCallback(
		(href: string, displayText: string | null) => {
			handleSubmit({
				id,
				url: href,
				title: displayText || '',
			});
			handleClose();
		},
		[handleClose, handleSubmit, id],
	);

	const searchProvider = useSearchProvider();
	const plugins: [LinkPickerPlugin] = useMemo(
		() => [
			new AtlassianLinkPickerPlugin({
				searchProvider,
				activityClientEndpoint: `${window.location.origin}/gateway/api/graphql`,
			}),
		],
		[searchProvider],
	);

	const location = 'confluenceShortcuts';

	const sendShortcutClickedAnalytics = (navdexPointType) => () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'spaceShortcut',
				source: 'containerNavigation',
				containerType: 'space',
				containerId: spaceId,
				attributes: {
					navVersion: '3',
					...(navdexPointType && { navdexPointType }),
					location,
				},
			},
		}).fire();
	};

	return (
		<>
			<CardComponent
				testId="shortcut-item"
				appearance="inline"
				url={url || ''}
				placeholder={title || undefined}
				onClick={sendShortcutClickedAnalytics(navdexPointType)}
				ui={{
					hideBackground: true,
					hideElevation: true,
					hidePadding: true,
					clickableContainer: true,
					size: SmartLinkSize.Large,
				}}
			>
				<TitleBlock
					direction={SmartLinkDirection.Horizontal}
					maxLines={1}
					position={SmartLinkPosition.Center}
					text={title || undefined}
					anchorTarget={target}
					hideTitleTooltip
					actions={
						isSpaceAdmin
							? [
									{
										name: ActionName.EditAction,
										onClick: openEdit,
										iconPosition: 'before',
										testId: 'editAction',
									},
									{
										name: ActionName.DeleteAction,
										onClick: () => deleteShortcut(item),
										iconPosition: 'before',
										testId: 'deleteAction',
										content: intl.formatMessage(i18n.shortcutsQuickActionsRemoveButton),
									},
								]
							: undefined
					}
					showActionOnHover
					onActionMenuOpenChange={({ isOpen }) => {
						setIsActionMenuOpen(isOpen);
					}}
				/>
			</CardComponent>
			<Popup
				autoFocus={false}
				isOpen={isEditOpen}
				onClose={() => handleClose()}
				content={(props) => (
					<div>
						<LazyLinkPicker
							{...props}
							plugins={plugins}
							onSubmit={({ url, displayText }) => {
								onSubmit(url, displayText);
							}}
							onCancel={handleClose}
							url={url || ''}
							displayText={title || ''}
						/>
					</div>
				)}
				placement="bottom-start"
				offset={[-42, 4]}
				trigger={(triggerProps) => (
					<PopupTrigger {...triggerProps}>&nbsp;This trigger is hidden</PopupTrigger>
				)}
			/>

			<Blanket
				data-testid="space-shortcuts-actions-blanket"
				isTinted={false}
				shouldAllowClickThrough={!isEditOpen && !isActionMenuOpen}
				onBlanketClicked={() => {
					setIsActionMenuOpen(false);
					setIsEditOpen(false);
				}}
			/>
		</>
	);
};

export const ShortcutsItem = withFlags(ShortcutsItemComponent);
