import { CardClient } from '@atlaskit/link-provider';
import { type EnvironmentsKeys } from '@atlaskit/linking-common';

type onResolveFn = (data: unknown | undefined) => void;

const deferIdle = (cb: (data: unknown) => void) => {
	if ('requestIdleCallback' in window) {
		requestIdleCallback(cb, { timeout: 5000 });
	} else {
		requestAnimationFrame(cb);
	}
};

export class ConfluenceSmartCardClient extends CardClient {
	onResolve: onResolveFn;

	constructor(props: { onResolve: onResolveFn; envKey?: EnvironmentsKeys }) {
		const { onResolve, envKey } = props;
		super(envKey);
		this.onResolve = onResolve;
	}

	// Called once per page load
	prefetchData(url: string): ReturnType<CardClient['prefetchData']> {
		return super.prefetchData(url).then((data) => {
			// Using deferIdle(requestIdleCallback) to ensure our onResolve callback doesn't interrupt the smart card client.
			// This means there's a chance under heavy browser load that the delay between client (pre)fetch and onResolve invocation will be >1s, but usually it shouldn't be.
			// Given the data schema is unstable, we're setting the type as unknown and expecting individual resolvers to be mindful.
			data && deferIdle(() => this.onResolve(data as unknown));
			return data;
		});
	}

	// Called on load and on click of each smart card link
	fetchData(url: string): ReturnType<CardClient['fetchData']> {
		return super.fetchData(url).then((data) => {
			data && deferIdle(() => this.onResolve(data as unknown));
			return data;
		});
	}
}
